<template>
  <div class="row">
    <div class="col-md-12 text-center">
      <span v-if="avatar" class="avatar-span">
        <img :src="avatar" />
      </span>
      <span v-else class="avatar-span">
        <i aria-hidden="true" class="fa fa-user fa-inverse"></i>
      </span>
    </div>
    <div class="col-md-12 mt-4">
      <input type="file"
        class="d-none"
        ref="fieldInput"
        :accept="types"
        multiple="false"
        @change="onUploadFile"
      />
      <div class="row">
        <div class="col text-center">
          <button :disabled="processing" class="btn btn-primary" @click.prevent="onClickUploadFile">
            <span v-if="!processing"><i class="fa fa-upload"></i> Upload</span>
            <span v-else><i class="fa fa-spinner fa-spin"></i></span>
          </button>
        </div>
        <div v-if="avatar" class="col text-center">
          <button :disabled="processing" class="btn btn-danger" @click.prevent="deleteFile">
            <span v-if="!processing"><i class="fa fa-trash"></i></span>
            <span v-else><i class="fa fa-spinner fa-spin"></i></span>
          </button>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
  import VuesticFileUpload from "../../vuestic-theme/vuestic-components/vuestic-file-upload/VuesticFileUpload";

  export default {
    components: {
      VuesticFileUpload,
    },
    name: 'app-image-upload',
    props: {
      types: {
        type: String,
        default: '.png, .jpg, .jpeg, .gif'
      },
      avatar: {
        type: String,
        default: null
      },
    },
    computed: {
    },
    mounted() {
    },
    data() {
      return {
        processing: false,
      }
    },
    methods: {
      onClickUploadFile() {
        this.$refs.fieldInput.click();
      },
      onUploadFile(e) {
        let files = e.target.files || e.dataTransfer.files
        let image = null
        if (files && files.length)
          image = files[0]

        this.uploadFile(image)
      },
      uploadFile(image) {
        this.processing = true;

        let event = {};
        let promise = Promise.resolve();

        event.image = image;
        event.waitUntil = p => promise = p;
        this.$emit('upload', event);

        promise.then((result) => {
          this.$refs.fieldInput.value = ''
          this.processing = false
        }).catch(err => {
          this.$refs.fieldInput.value = ''
          this.processing = false
        })
      },

      deleteFile() {
        this.uploadFile(null)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .vuestic-file-upload-field--dropzone {
    padding: 0 .7rem !important;
    height: 2.4rem !important;
  }

  .input-group-company-logo {
    .vuestic-file-upload-single {
      height: 60px;

      button {
        display: none;
      }
    }
  }

  .image-upload-preview {
    img {
      max-width: 100%;
      max-height: 100px;
      margin: 5px auto;
    }
    .actions {
      text-align: right;
    }
  }

  .overlay-dim {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;

    position: absolute;
    top:0;
    left:0;
    bottom: 0;
    right:0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
</style>
