<template>
  <vuestic-widget headerText="My Profile">

    <div class="profile-wrapper">
      <div class="left-wrapper">
        <avatar-upload
          @upload="uploadImage"
          :avatar="avatar"
        ></avatar-upload>
      </div>
      <div class="right-wrapper">
        <ValidationObserver v-slot="{ handleSubmit }" ref="settingForm">
          <form @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div class="row">
              <div class="col-6">
                <text-input name="firstName" v-model="profile.first_name" label="FirstName" :validate="'required'"/>
              </div>
              <div class="col-6">
                <text-input name="lastName" v-model="profile.last_name" label="LastName" :validate="'required'"/>
              </div>
              <div class="col-12">
                <text-input name="email" label="email" v-model="profile.email" :validate="'required'"/>
              </div>
              <div class="col-12">
                <text-input name="password" type="password" label="Password" v-model="profile.password" autocomplete="new-password"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-auto">
                <Button classes="btn btn-primary" :loading="loading">
                  Update
                </Button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </vuestic-widget>
</template>

<script>
  import AvatarUpload from "../common/AvatarUpload";
  import axios from 'axios'
  export default {
    name: "Profile",
    components: {
      AvatarUpload,
    },
    data() {
      return {
        profile: {
          first_name: '',
          last_name: '',
          email: '',
          password: ''
        }
      }
    },

    computed: {
      loading() {
        return this.$store.state.auth.loading
      },
      user() {
        return this.$store.state.auth.user;
      },
      avatar() {
        return this.user && this.user.avatar
      },
    },

    methods: {
      uploadImage(event) {
        event.waitUntil(new Promise((resolve, reject) => {
          let postData = new FormData();
          postData.append('image', event.image);
          axios.post(`/v1/profile/image`, postData)
            .then(({data}) => {
              if (data.success) {
                this.$store.commit("auth/getProfileSuccess", data.data.user);
                resolve();
              } else {
                reject();
              }
            });
        }))
      },

      save() {
        this.$store.dispatch('auth/updateProfile', this.profile)
          .catch(() => {})
      }
    },

    mounted() {
      let user = JSON.parse(JSON.stringify(this.$store.state.auth.user))
      this.profile.first_name = user.first_name
      this.profile.last_name = user.last_name
      this.profile.email = user.email
    },
  }
</script>

<style scoped>
  .profile-wrapper {
    display: flex;
    padding: 0 30px;
    max-width: 1024px;
  }
  .left-wrapper {
    flex: 0 0 250px;
  }
  .right-wrapper {
    flex: 1 0 auto;
    padding-left: 30px;
    padding-top: 10px;
  }
</style>
